import type { Get } from './get';
export namespace Derived {
  export type ModalState = {
    buildDate: string;
    handleFormSubmit?: () => void;
    hardware: Get.HardwareUnit;
    location: string;
    model: string;
    owner: string;
    reroutePath?: string;
    status: string;
  };

  export type SearchForm = {
    buildDateEnd: string;
    buildDateStart: string;
    locationId: string;
    modelId: string;
    serialNumber: string;
    statusId: string;
    type: string;
    vendorSaleInvoice: string;
  };

  export type HardwareEditForm = {
    buildDate: Date;
    customerOwnerId: string;
    la1SaleInvoice: string;
    locationId: string;
    modelId: string;
    notes: string;
    saleDate: Date;
    serial: string;
    statusId: string;
    vendorSaleInvoice: string;
    warrantyLength: number;
  };

  export type HardwareCount = Record<string, number>;

  export type CurrentGenData = {
    customerOwned?: number;
    dealerOwned?: number;
    decommissioned?: number;
    demoOut?: number;
    internalUse?: number;
    model?: string;
    needsRefurb?: number;
    needsRepairThenRefurb?: number;
    rentalOut?: number;
    stockInternalUse?: number;
    stockNew?: number;
    stockNewOffsite?: number;
    stockRefurbished?: number;
    totalCount?: number;
    unknown?: number;
  };

  export type HardwareStatusPreData = Record<HARDWARE_STATUS_TYPE, number>;

  export enum HARDWARE_STATUS_TYPE {
    STATUS_CUSTOMER_OWNED = 'Customer Owned',
    STATUS_DEALER_OWNED = 'Dealer Owned',
    STATUS_DECOM = 'Decommissioned',
    STATUS_DEMO_OUT = 'Demo - Out',
    STATUS_INTERNAL_USE = 'Internal Use',
    STATUS_NEEDS_REFURB = 'Needs Refurb',
    STATUS_NEEDS_REPAIR_THEN_REFURB = 'Needs Repair, Then Refurb',
    STATUS_RENTAL_OUT = 'Rental - Out',
    STATUS_STOCK_INTERNAL_USE = 'Stock - Internal Use',
    STATUS_STOCK_NEW = 'Stock - New',
    STATUS_STOCK_NEW_OFFSITE = 'Stock - New (Offsite)',
    STATUS_STOCK_REFURB = 'Stock - Refurbished',
    STATUS_TOTAL = 'Total',
    STATUS_UNKNOWN = 'Unknown',
  }

  export enum HARDWARE_CYCLE {
    CURRENT_GEN = 'Current Gen',
    DISCONTINUED = 'Discontinued',
    END_OF_LIFE = 'End of Life',
  }

  export type HardwareCycleGeneric = Record<string, string>;
  export type HardwareModelGeneric = Record<string, Get.HardwareModel>;
}
